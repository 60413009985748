"use strict";

function refreshInfoBox(marker) {
  map.panTo(marker.position);
  $('#infobox').slideUp(0, function (e) {
    $('#infobox').removeClass('options-selected');
    $('#ib-options-list').slideUp();

    if (!marker.isBig) {
      $('#infobox #box-comments').slideDown(0);
      FOS_COMMENT.getThreadComments(marker.id);
    } else $('#infobox #box-comments').slideUp(0);

    $('#infobox').slideDown(500);
  });
}

function addBurgerInfoBox(marker) {
  map.panTo(marker.position);
  $('#infobox').slideUp(0, function (e) {
    $('#infobox').find('#title').html(marker.title);
    $('#infobox').find('#address').html(marker.vicinity);
    $('#infobox #ib-form').html('<form action="POST"><div><label>You can format the name</label><input name="name" type="text" value="' + marker.title + '" /></div><div><input type="submit" value="Send your burger" /></div><input name="city" type="hidden" value="' + marker.locality + '" /><input name="address" type="hidden" value="' + marker.vicinity + '" /><input name="lon" type="hidden" value="' + marker.position.lng() + '" /><input name="lat" type="hidden" value="' + marker.position.lat() + '" /></form>').show();
    $('#infobox').slideDown(500);
  });
}

function addBurgerByCityInfoBox(marker) {
  map.panTo(marker.position);
  $('#infobox').slideUp(0, function (e) {
    $('#infobox').find('#title').html(marker.title);
    $('#infobox').find('#address').html(marker.vicinity);
    $('#infobox #ib-form').html('<form action="POST"><div><label for="name">Name</label><input name="name" type="text" value="' + marker.title + '" /></div><div><label for="name">Address</label><input name="address" type="text" value="' + marker.vicinity + '" /></div><div><label for="name">City</label><input name="city" value="' + marker.locality + '" /></div><div style="margin-top:4px;"><input type="submit" value="Send your burger" style="margin-top:4px;" /></div><div><input type="submit" value="next" style="margin-top:4px;" /><input name="lon" type="hidden" value="' + marker.position.lng() + '" /><input name="lat" type="hidden" value="' + marker.position.lat() + '" /></form>').show();
    $('#infobox').slideDown(500);
  });
}

$(document).on('click', '#comments > .fos_comment_comment_form_holder > form > .panel > .panel-heading > .panel-title', function (e) {
  e.preventDefault();
  $('#comments > .fos_comment_comment_form_holder > form > .panel > .panel-body').slideToggle(500);
});